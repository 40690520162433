<template>
  <div class="page">
    <!-- 搜索条件表单 -->
    <el-form class="ser_form" size="small" label-width="90px">
      <el-form-item class="el_form_item" label="流水编号">
        <el-input
          class="el_input"
          v-model="form.ra_num"
          placeholder="流水编号搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="运单编号">
        <el-input
          class="el_input"
          v-model="form.truck_tord_num"
          placeholder="运单编号搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="货主手机号">
        <el-input
          class="el_input"
          v-model="form.shipper_tel"
          placeholder="货主手机号搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="车主手机号">
        <el-input
          class="el_input"
          v-model="form.truck_owner_tel"
          placeholder="车主手机号搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="司机手机号">
        <el-input
          class="el_input"
          v-model="form.driver_tel"
          placeholder="司机手机号搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="支付类型">
        <el-select class="el_input" v-model="form.this_pay_type" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="现金" value="1"></el-option>
          <el-option label="燃油费" value="2"></el-option>
          <el-option label="过路费" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item" label="开票状态">
        <el-select class="el_input" v-model="form.invoice_status" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="未申请" value="1"></el-option>
          <el-option label="申请中" value="2"></el-option>
          <el-option label="已开出" value="3"></el-option>
          <el-option label="已驳回" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item" label="结算状态">
        <el-select class="el_input" v-model="form.settle_status" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="未申请" value="1"></el-option>
          <el-option label="待打款" value="2"></el-option>
          <el-option label="已打款" value="3"></el-option>
          <el-option label="结算失败" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item" label="备注">
        <el-input
          class="el_input"
          v-model="form.mark"
          placeholder="备注搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label-width="0">
        <el-button type="primary" @click="cargo_ser">查询</el-button>
        <el-button type="primary" @click="ser_para_clear">清空条件</el-button>
        <el-button type="primary" @click="excel_out">导出</el-button>
        <el-button type="primary" @click="batch_invoice">批量开出</el-button>
      </el-form-item>
    </el-form>
    <div class="total_info">
      <span>共:{{ list.total}}条</span>
      <span>总油费:{{  }}元</span>
    </div>
    <!-- 表格 -->
    <div class="tab_height">
      <el-table
        :data="list.data"
        :border="true"
        :stripe="true"
        size="small"
        v-loading="list.loading"
        @selection-change="select_table"
      >
        <el-table-column type="selection" width="40" align="center">
        </el-table-column>
        <el-table-column label="支付编号/运单编号" width="210px">
          <template slot-scope="scope">
            <div class="tab_line_item">支付: {{ scope.row.ra_num }}</div>
            <div class="tab_line_item">
              运单: {{ scope.row.truck_tord_num }}
            </div>
            <div class="tab_line_item">
              发起: {{ scope.row.creat_time_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="装卸货地信息">
          <template slot-scope="scope">
            <div class="tab_line_item">
              (省市区) {{ scope.row.tord_info.case_prov }} /
              {{ scope.row.tord_info.case_city }} /
              {{ scope.row.tord_info.case_county }}
            </div>
            <div class="tab_line_item">
              (详&nbsp;&nbsp;&nbsp;细)
              {{
                scope.row.tord_info.case_addr
                  ? scope.row.tord_info.case_addr
                  : "无"
              }}
            </div>
            <div class="tab_line_item">
              (省市区) {{ scope.row.tord_info.aim_prov }} /
              {{ scope.row.tord_info.aim_city }} /
              {{ scope.row.tord_info.aim_county }}
            </div>
            <div class="tab_line_item">
              (详&nbsp;&nbsp;&nbsp;细)
              {{
                scope.row.tord_info.aim_addr
                  ? scope.row.tord_info.aim_addr
                  : "无"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="货主信息" width="160px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              {{ scope.row.shipper_info.name }} /
              {{ scope.row.shipper_info.tel }}
            </div>
            <div class="tab_line_item">
              {{ scope.row.shipper_info.company_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="车主/司机" width="190px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              车主: {{ scope.row.truck_owner_info.name }} /
              {{ scope.row.truck_owner_info.tel }}
            </div>
            <div class="tab_line_item">
              司机: {{ scope.row.driver_info.name }} /
              {{ scope.row.driver_info.tel }}
            </div>
            <div class="tab_line_item">
              货车: {{ scope.row.tord_info.truck_plate_num }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="支付描述" width="130px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              {{ scope.row.this_pay_type_text }} /
              {{ scope.row.this_pay_is_last_text }}
            </div>
            <div class="tab_line_item">
              备注: {{ scope.row.mark ? scope.row.mark : "无" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="服务费" width="140px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              本次付: {{ scope.row.this_pay_freight }}元
            </div>
            <div class="tab_line_item">
              费率: {{ scope.row.extra_service_charge_rate }} /
              {{ scope.row.extra_service_charge_type_text }}
            </div>
            <div class="tab_line_item">
              服务费: {{ scope.row.extra_service_charge_total }}元
            </div>
          </template>
        </el-table-column>
        <el-table-column label="支付状态" width="140px">
          <template slot-scope="scope">
            <div class="tab_line_item">金额: {{ scope.row.this_payed }}元</div>
            <div class="tab_line_item">{{ scope.row.pay_status_text }}</div>
            <div class="tab_line_item">{{ scope.row.payed_time_text }}</div>
          </template>
        </el-table-column>
        <el-table-column label="发票状态" width="170px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              金额: {{ scope.row.this_invoiced }}元
            </div>
            <div class="tab_line_item">
              {{ scope.row.invoice_status_text }}
              <el-button
                v-if="scope.row.invoice_status == 2"
                class="btn_left"
                @click="invoice_done(scope.row)"
                size="mini"
                type="text"
                >开出</el-button
              >
              <el-button
                v-if="scope.row.invoice_status == 2"
                class="btn_left"
                @click="invoice_refuse(scope.row)"
                size="mini"
                type="text"
                style="color: red"
                >驳回</el-button
              >
            </div>
            <div class="tab_line_item">{{ scope.row.invoiced_time_text }}</div>
          </template>
        </el-table-column>
        <el-table-column label="结算状态" width="170px">
          <template slot-scope="scope">
            <div class="tab_line_item">
              金额: {{ scope.row.this_settled }}元 /
              {{ scope.row.this_pay_type_text }}
            </div>
            <div class="tab_line_item">{{ scope.row.settle_status_text }}</div>
            <div class="tab_line_item">{{ scope.row.settled_time_text }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <el-pagination
      class="margin-top-xl"
      @size-change="page_size_change"
      @current-change="page_current_change"
      :current-page="page.p"
      :page-sizes="[10, 50, 100, 200]"
      :page-size="page.num"
      :total="list.total * 1"
      layout="total, sizes,prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>

<script>
import xlsx from "xlsx";
export default {
  data() {
    return {
      //搜索条件
      form: {
        ra_num: "", //货源编号
        truck_cargo_num: "", //货源编号
        shipper_tel: "", //货主手机号
        truck_owner_tel: "", //车主手机号
        driver_tel: "", //司机手机号
        this_pay_type: "", //本次支付类型(1:现金,2:燃油费,3:过路费)
        pay_status: ["1", "2"], //支付状态(1:待审核,2:支付完成,3:支付失败/打回)
        invoice_status: "", //开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
        settle_status: "", //结算给车主的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
        mark: "", //备注
      },

      //表格数据
      list: {
        loading: false, //loading特效
        data: [], //列表数据
        total: 0, //列表总记录数
      },
      batchConfirm: [],
      //页码信息
      page: {
        need_num: 1,
        num: 10,
        p: 1,
      },
    };
  },
  created() {
    //读取货源
    this.get_page_data();
  },
  methods: {
    //批量开出
    batch_invoice() {
      if (this.batchConfirm.length == 0) {
        this.$message({
          type: "info",
          message: "当前未勾选数据",
        });
        return;
      }
      this.$my.other.confirm({
        content: "确定开出?",
        confirm: () => {
          //调用接口
          this.$my.net.req({
            data: {
              mod: "truck_tord_real_time",
              ctr: "tord_invoice_done_by_admin",
              truck_tord_num: this.batchConfirm,
            },
            callback: (data) => {
              //提醒
              this.$my.other.msg({
                str: "操作成功",
                type: "success",
              });

              //刷新本页
              this.get_page_data();
            },
          });
        },
      });
    },
    // 勾引数据
    select_table(item) {
      var truck_tord_num = [];
      item.forEach((item) => {
        truck_tord_num.push(item.truck_tord_num);
      });
      this.batchConfirm = truck_tord_num;
    },
    // //跳转轨迹查询
    // see_trajectory(){
    // 	this.$router.push('/pages/transit_real_time/tord/maptrajectory')
    // },
    //撤回
    invoice_revoke(item) {
      //询问
      this.$my.other.confirm({
        content: "点击确定撤回开票",
        confirm: () => {
          //调用接口
          this.$my.net.req({
            data: {
              mod: "truck_tord_real_time",
              ctr: "tord_invoice_revoke_by_admin",
              truck_tord_num: item.truck_tord_num,
              ra_num: item.ra_num,
            },
            callback: (data) => {
              //刷新本页
              this.get_page_data();
            },
          });
        },
      });
    },

    //驳回
    invoice_refuse(item) {
      //询问
      this.$my.other.confirm({
        content: "点击确定驳回开票申请",
        confirm: () => {
          //调用接口
          this.$my.net.req({
            data: {
              mod: "truck_tord_real_time",
              ctr: "tord_invoice_refuse_by_admin",
              truck_tord_num: item.truck_tord_num,
              ra_num: item.ra_num,
            },
            callback: (data) => {
              //刷新本页
              this.get_page_data();
            },
          });
        },
      });
    },

    //确定开出
    invoice_done(item) {
      //询问
      this.$my.other.confirm({
        content: "确定开出?",
        confirm: () => {
          //调用接口
          this.$my.net.req({
            data: {
              mod: "truck_tord_real_time",
              ctr: "tord_invoice_done_by_admin",
              truck_tord_num: item.truck_tord_num,
              ra_num: item.ra_num,
            },
            callback: (data) => {
              //提醒
              this.$my.other.msg({
                str: "操作成功",
                type: "success",
              });

              //刷新本页
              this.get_page_data();
            },
          });
        },
      });
    },

    //清空查询条件
    ser_para_clear() {
      this.form = {
        ra_num: "", //货源编号
        truck_cargo_num: "", //货源编号
        shipper_tel: "", //货主手机号
        truck_owner_tel: "", //车主手机号
        driver_tel: "", //司机手机号
        this_pay_type: "", //本次支付类型(1:现金,2:燃油费,3:过路费)
        pay_status: ["1", "2"], //支付状态(1:待审核,2:支付完成,3:支付失败/打回)
        invoice_status: "2", //开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
        settle_status: "", //结算给车主的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
        mark: "", //备注
      };
      this.get_page_data();
    },

    //导出
    excel_out() {
      var ids = this.change_data;
      var check_state = this.check_state;
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "tord_payed_list_by_admin_excelout",
          ...this.form,
          ...this.page,
        },
        callback: (data) => {
          const _data = [
            // 代表 导出 列标题
            [
              "系统id",
              "运单编号",
              "商户运单号",
              "申请开票时间",
              "货主名称",
              "货主手机号",
              "抬头",
              "税号",
              "开票状态",
              "总开票额",
              "货物体积(方)",
              "货物重量(吨)",
              "货物名称",
              "司机电话",
              "司机姓名",
              "车型",
              "细分类型",
              "车牌号",
              "发货地省份",
              "发货地城市",
              "发货地县区",
              "发货地详细地址",
              "发车时间",
              "到货地省份",
              "到货地城市",
              "到货地县区",
              "到货地详细地址",
              "到货时间",
              "备注",
            ],
          ];
          // data.list.forEach((item) => {
          //   _data.push(Object.values(item));
          // });
          // const ws = xlsx.utils.aoa_to_sheet(_data);
          // const wb = xlsx.utils.book_new();
          // xlsx.utils.book_append_sheet(wb, ws, "SheetJS");
          // xlsx.writeFile(wb, "运单信息.xlsx");
        },
      });
    },

    //搜索
    cargo_ser() {
      this.get_page_data();
    },

    //页宽变化
    page_size_change(num) {
      //置入页码
      this.page.num = num;

      //读取
      this.get_page_data();
    },

    //页码变化
    page_current_change(p) {
      //置入页码
      this.page.p = p;

      //读取
      this.get_page_data();
    },

    //获取货源列表
    get_page_data() {
      //加载中...
      if (this.list.loading) {
        return;
      }

      //置为加载中
      this.list.loading = true;

      //读取数据
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "tord_payed_list_by_admin",
          ...this.form,
          ...this.page,
        },
        callback: (data) => {
          //加载完毕
          this.list.loading = false;

          //总数
          this.list.total = data.max;

          //预处理
          for (let item of data.list) {
            //数据空值初始化
            item.truck_info = item.truck_info ? item.truck_info : {};
            item.driver_info = item.driver_info ? item.driver_info : {};
            item.truck_owner_info = item.truck_owner_info
              ? item.truck_owner_info
              : {};
            item.shipper_info = item.shipper_info ? item.shipper_info : {};

            //成交时间
            item.creat_time_text = this.$my.other.totime(item.creat_time);

            //本次支付类型(1:现金,2:燃油费,3:过路费)
            switch (item.this_pay_type) {
              case "1":
                item.this_pay_type_text = "现金";
                break;
              case "2":
                item.this_pay_type_text = "燃油费";
                break;
              case "3":
                item.this_pay_type_text = "过路费";
                break;
            }

            //是否是最后一笔支付(1:是,2:不是)
            switch (item.this_pay_is_last) {
              case "1":
                item.this_pay_is_last_text = "最后一笔";
                break;
              case "2":
                item.this_pay_is_last_text = "非最后一笔";
                break;
            }

            //额外服务费计算方式(1:除法模式,2:乘法模式)
            switch (item.extra_service_charge_type) {
              case "1":
                item.extra_service_charge_type_text = "除法模式";
                break;
              case "2":
                item.extra_service_charge_type_text = "乘法模式";
                break;
            }

            //支付状态(1:待审核,2:支付完成,3:支付失败/打回)
            switch (item.pay_status) {
              case "1":
                item.pay_status_text = "待审核";
                break;
              case "2":
                item.pay_status_text = "支付完成";
                break;
              case "3":
                item.pay_status_text = "支付失败";
                break;
            }

            //完成/打回 时间
            if (item.pay_status != 1) {
              item.payed_time_text = this.$my.other.totime(item.payed_time);
            } else item.payed_time_text = "";

            //开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
            switch (item.invoice_status) {
              case "1":
                item.invoice_status_text = "未申请";
                break;
              case "2":
                item.invoice_status_text = "申请中";
                break;
              case "3":
                item.invoice_status_text = "已开出";
                break;
              case "4":
                item.invoice_status_text = "已驳回";
                break;
            }

            //开票完成时间
            if (["3", "4"].indexOf(item.invoice_status) != -1) {
              item.invoiced_time_text = this.$my.other.totime(
                item.invoiced_time
              );
            } else item.invoiced_time_text = "";

            //结算给车主的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
            switch (item.settle_status) {
              case "1":
                item.settle_status_text = "未申请";
                break;
              case "2":
                item.settle_status_text = "待打款";
                break;
              case "3":
                item.settle_status_text = "已打款";
                break;
              case "4":
                item.settle_status_text = "打款失败";
                break;
            }

            //结算完成时间
            if (["3", "4"].indexOf(item.settle_status) != -1) {
              item.settled_time_text = this.$my.other.totime(item.settled_time);
            } else item.settled_time_text = "";
          }

          //渲染
          this.list.data = data.list;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  // height: calc(100% - 40px);
  overflow: auto;
}
.ser_form {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.tab_height {
  height: calc(100% - 134px);
}
.el_form_item {
  width: 275px;
}
.el_input {
  width: 94%;
}
.btn_left {
  margin: 0;
}
.total_info {
  text-align: right;
  font-size: 12px;
  margin-bottom: 10px;
  margin-right: 1px;
  color: #606266;
  span {
    margin-left: 10px;
  }
}
</style>